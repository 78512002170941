<template>
    <div class="login-page">
        <div class="login-box">
            <!-- /.login-logo -->
            <div class="card card-outline card-primary">
                <div class="card-body p-5">
                    <div class="text-center font-size-30 mb-5">
                        <img src="images/logo-acc-transparent-50w.png" /> PoS
                    </div>
                    <p class="login-box-msg">{{ status }}</p>

                    <form action="#" method="post" @submit="login">
                        <div class="input-group mb-3">
                            <input type="email" v-model="formData.email" class="form-control" placeholder="Email" required>
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-envelope"></span>
                                </div>
                            </div>
                        </div>
                        <div class="input-group mb-3">
                            <input type="password" v-model="formData.password" class="form-control" placeholder="Password" required>
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-lock"></span>
                                </div>
                            </div>
                        </div>
                        <!--<div class="icheck-primary">
                            <input type="checkbox" id="remember">
                                <label for="remember">
                                Remember Me
                                </label>
                        </div>-->
                        <div><button type="submit" class="btn btn-primary btn-block mt-4"><i class="fas fa-user-lock"></i> Sign In</button></div>
                    </form>

                    <div class="mt-5">
                        <router-link :to="{ name: 'Forgot' }"><i class="far fa-question-circle mr-2 mb-2"></i> Forgot my password</router-link><br />
                        <router-link :to="{ name: 'Signup' }" class="text-center"><i class="fas fa-user-plus mr-1"></i> Signup for an account</router-link>
                    </div>
                </div>
                <!-- /.card-body -->
            </div>
            <!-- /.card -->
        </div>
        <!-- /.login-box -->
    </div>
</template>

<script>
    import Vue from 'vue'
    import {UserService} from "@/services/user-service";
    let userService = new UserService();
    export default {
        name: "Login",
        data: () => {
            return {
                status: 'Sign in to start your session',
                formData: {
                    id : 0,
                    role_id : 2,
                    company_id: null,
                    name: null,
                    email: null,
                    email_verified_at: null,
                    password: null,
                    permissions: [],
                    stores: [],
                    image: null,
                    editable: null,
                    status: null,
                },
            }
        },
        methods: {
            async login(e) {
                e.preventDefault();
                let loader = this.$loading.show();

                userService.formDataUser = this.formData;
                let loginResponse = await userService.login();

                if(loginResponse.isSuccess){
                    let userInitResponse = await userService.userInit();
                    if(userInitResponse.isSuccess){
                        loader.hide();

                        await this.$router.push({name: 'App.Dashboard'});
                    }else{
                        Vue.$toast.open({ message: 'You are not authorized!',  type: 'error'});
                        loader.hide();
                    }
                }else{
                    console.log(loginResponse.status);
                    Vue.$toast.open({ message: loginResponse.message,  type: loginResponse.status});
                    loader.hide();
                }
            }
        },
        created() {
            //this.$loading.show();
        }
    }
</script>

<style scoped>

</style>